<template>
  <div class="wrap-edit">
    <div class="edit-header">
      <span>地块信息修改</span>
      <el-button type="primary" @click="onSubmit">保存</el-button>
    </div>
    <div class="edit-container">
      <p>地块信息</p>
      <el-form ref="form" :model="form" label-width="120px">
        <el-col :span="12" :xs="24">
          <el-form-item label="地块编号:">
            <el-input v-model="form.Id"></el-input>
          </el-form-item>
          <el-form-item label="土地位置:">
            <el-input v-model="form.LandLocation"></el-input>
          </el-form-item>
          <el-form-item label="范围:">
            <el-input v-model="form.name"></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="12" :xs="24">
          <el-form-item label="地块名称:">
            <el-input v-model="form.ProjectName"></el-input>
          </el-form-item>
          <el-form-item label="土地用途:">
            <el-input v-model="form.name2"></el-input>
          </el-form-item>
          <el-form-item label="乡镇/街道:">
            <el-input v-model="form.name2"></el-input>
          </el-form-item>
        </el-col>
      </el-form>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        Id: "",
        ProjectName: "",
        LandUser:"",
        LandLocation:"",
        name:"",
        name2:"",
      },
      dataList:{}
    };
  },
  methods: {
    onSubmit() {
      console.log("submit!");
    },
    //转化json
    switch(){
      this.dataList=this.$route.params.row
      if(this.dataList){
          let dataList2=JSON.parse(this.dataList) 
      this.form=dataList2
      }
      
    }
  },
  mounted () {
    this.switch()
  },
};
</script>

<style scoped>
.edit-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.el-form{
    width: 100%;
    /* border: 1px solid red; */
    overflow: hidden;
}
</style>